<template>
  <div>
    <ArtistCardLabel
      :label="t('date')"
      :value="
        date &&
        `${t(`weekdays.${getDateObject(date, timezoned).day()}`)} ${formatDate(
          date,
          'D.MM',
          timezoned
        )}`
      "
      :size="size"
    />
    <ArtistCardLabel :label="t('stage')" :value="place" :size="size" />
  </div>
</template>

<script lang="ts" setup>
withDefaults(
  defineProps<{
    date?: string
    place?: string
    size?: 'small' | 'big' | 'medium'
    timezoned?: boolean
  }>(),
  { size: 'medium', timezoned: true }
)

const { t } = useI18n()
</script>
